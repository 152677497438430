import React, { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import styles from "./NavigationDesktop.module.scss"
import resume from "../../misc/mikejhartman_resume.pdf"

class NavigationDesktop extends Component {
  render() {
    return (
      <div className="flex-grow text-right hidden sm:block">
        
        <AnchorLink  className={`mr-6 font-medium no-underline text-sm ${styles.navLink}`} to="/work">Work</AnchorLink>
        <AnchorLink  className={`mr-6 font-medium no-underline text-sm ${styles.navLink}`} to="/about">About</AnchorLink>
        <AnchorLink  className={`mr-6 font-medium no-underline text-sm ${styles.navLink}`} to="/thoughts">Thoughts</AnchorLink>
        <a  className={`font-medium no-underline text-sm ${styles.navLink} hover:text-gray-600`} href={resume} target="_blank" rel="noreferrer">Résumé</a>
      </div>
    )
  }
}

export default NavigationDesktop
