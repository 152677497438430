import React from "react"
import PropTypes from "prop-types"

const SVGIcon = props => {
  let iconToRender = ""

  switch (props.icon) {
    case "mail":
      iconToRender = (
        <svg
          className={props.className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          id="entypo-mail"
          width={props.width}
          height={props.height}
          fill="currentColor"
        >
          <g>
            <path d="M1.574 5.286l7.5 4.029c.252.135.578.199.906.199.328 0 .654-.064.906-.199l7.5-4.029c.489-.263.951-1.286.054-1.286H1.521c-.897 0-.435 1.023.053 1.286zm17.039 2.203l-7.727 4.027c-.34.178-.578.199-.906.199s-.566-.021-.906-.199-7.133-3.739-7.688-4.028C.996 7.284 1 7.523 1 7.707V15c0 .42.566 1 1 1h16c.434 0 1-.58 1-1V7.708c0-.184.004-.423-.387-.219z" />
          </g>
        </svg>
      )
      break

    case "caret-up":
      iconToRender = (
        <svg
          className={props.className}
          width={props.width}
          height={props.height}
          viewBox="0 0 20 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Inspiration"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Home-Page"
              transform="translate(-1150.000000, -2217.000000)"
              fill="#FFFFFF"
            >
              <polygon
                id="Triangle-Copy"
                transform="translate(1160.000000, 2224.000000) scale(-1, 1) translate(-1160.000000, -2224.000000) "
                points="1160 2217.7 1169.45 2230.3 1150.55 2230.3"
              ></polygon>
            </g>
          </g>
        </svg>
      )
      break

    case "linkedin":
      iconToRender = (
        <svg
          className={props.className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          id="entypo-linkedin-with-circle"
          width={props.width}
          height={props.height}
          fill="currentColor"
        >
          <g>
            <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z" />
          </g>
        </svg>
      )
      break

    case "work":
      iconToRender = (
        <svg
        className={props.className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          id="entypo-folder"
          width={props.width}
          height={props.height}
          fill="currentColor"
        >
          <g>
            <path d="M18.405 4.799c-.111-.44-.655-.799-1.21-.799h-6.814c-.554 0-1.33-.318-1.722-.707l-.596-.588C7.671 2.316 6.896 2 6.342 2H3.087c-.555 0-1.059.447-1.12.994L1.675 6h16.931l-.201-1.201zM19.412 7H.588a.58.58 0 0 0-.577.635l.923 9.669A.77.77 0 0 0 1.7 18h16.6a.77.77 0 0 0 .766-.696l.923-9.669A.58.58 0 0 0 19.412 7z" />
          </g>
        </svg>
      )

      break

    case "handle":
      iconToRender = (
        <svg
          className={props.className}
          width={props.width}
          height={props.height}
          viewBox="0 0 27 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Group 3</title>
          <g
            id="Inspiration"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Project-Page"
              transform="translate(-574.000000, -819.000000)"
              fill="#FFFFFF"
            >
              <g id="Group-2" transform="translate(569.520122, 809.000000)">
                <g id="Group-3" transform="translate(5.708333, 10.333333)">
                  <polygon
                    id="Triangle"
                    transform="translate(19.583333, 7.666667) rotate(90.000000) translate(-19.583333, -7.666667) "
                    points="19.5833333 3 26.5833333 12.3333333 12.5833333 12.3333333"
                  ></polygon>
                  <polygon
                    id="Triangle-Copy"
                    transform="translate(5.000000, 7.666667) scale(-1, 1) rotate(90.000000) translate(-5.000000, -7.666667) "
                    points="5 3 12 12.3333333 -2 12.3333333"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )
      break

    default:
      iconToRender = <span>Wrong.</span>
      break
  }

  return <>{iconToRender}</>
}

SVGIcon.propTypes = {
  icon: PropTypes.oneOf(["mail", "caret-up", "linkedin", "handle", "work"])
    .isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default SVGIcon
