/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./Layout.scss" // don't forget to import this before any components, dummy

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import ScrollToTop from "../ScrollToTop/ScrollToTop"

const Layout = ({ children }) => {
  
  return (
    <div>
      <div className="container mx-auto mt-8 px-10 sm:px-12 md:px-16 lg:px-24 xl:px-40 text-left">
        <Header />
        <ScrollToTop scrollValue="700"/>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
