import React, { Component } from "react"
import { CSSTransition } from "react-transition-group"

import SVGIcon from "../SVGIcon/SVGIcon"

import styles from "./ScrollToTop.module.scss"

class ScrollToTop extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      showScroll: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener("scroll", this.checkScrollTop)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener("scroll", this.checkScrollTop)
  }

  checkScrollTop = () => {
    if (!this.state.showScroll && window.pageYOffset > this.props.scrollValue) {
      if (this._isMounted) this.setState({ showScroll: true })
    } else if (
      this.state.showScroll &&
      window.pageYOffset <= this.props.scrollValue
    ) {
      if (this._isMounted) this.setState({ showScroll: false })
    }
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  render() {
    const showScroll = this.state.showScroll

    return (
      <>
        <CSSTransition
          timeout={150}
          in={showScroll}
          className={`${styles.scrollToTopButton} `}
          classNames={{ ...styles }}
          onClick={this.scrollToTop}
        >
          <div>
            <SVGIcon icon="caret-up" width="20" height="14"/>
            
          </div>
        </CSSTransition>
      </>
    )
  }
}

export default ScrollToTop
