import React, { Component } from "react"
import { Link } from "gatsby"

import NavigationDesktop from "../NavigationDesktop/NavigationDesktop"
import NavigationMobile from "../NavigationMobile/NavigationMobile"

import logo from "./assets/mjhbbgsquare.svg"

class Header extends Component {
  render() {
    return (
      <div className="flex items-center">
        <Link to="/"><img src={logo} alt="Mike J Hartman Logo" height="50" width="50" className="max-w-none"/></Link>
        <NavigationDesktop />
        <NavigationMobile />
      </div>
    )
  }
}

export default Header
