import React from "react";

import resume from "../../misc/mikejhartman_resume.pdf"
import SVGIcon from "../SVGIcon/SVGIcon";

import styles from './Footer.module.scss';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Footer = props => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <div style={{backgroundColor: "#101215"}} className={"w-full pt-16 pb-24 px-10 sm:px-12 md:px-16 lg:px-24 xl:px-40 text-left mt-24"}>
            <div className={"mx-auto w-full text-white flex flex-row flex-wrap"} style={{maxWidth: "38rem"}}>
                
                <div className="w-full">

                   {/*  <h3>Thanks for stopping by.</h3> */}
                    <h4>Questions? Concerns? Want to hear my thoughts on CSS-in-JS?</h4><h4>Let me know!</h4>
                </div>
 
                <div className="w-full flex flex-row flex-wrap mt-6">
                    <div className="mr-12 mb-6">
                    <p style={{color: "#5D5D5D"}} className="mb-1 font-semibold text-sm">Navigation</p>
                     <div className={`mb-2 ${styles.navContainer}`}><AnchorLink to="/">Home</AnchorLink></div>
                        <div className={`mb-2 ${styles.navContainer}`}><AnchorLink to="/work">Work</AnchorLink></div>
                     <div className={`mb-2 ${styles.navContainer}`}><AnchorLink to="/about">About</AnchorLink></div>
                        <div className={`mb-2 ${styles.navContainer}`}><AnchorLink to="/thoughts">Thoughts</AnchorLink></div>
                        <div className={`mb-2 ${styles.navContainer}`}><a href={resume} target="_blank" rel="noreferrer">Résumé</a></div>
                    
                       
                    </div>
                    <div className="mb-6" >
                    <p style={{color: "#5D5D5D"}} className="mb-1 font-semibold text-sm">Contact</p>
                        <div className={`mb-2 ${styles.navContainer}`}><a href="mailto:mikejhartman@gmail.com" className={`flex items-center`}><SVGIcon icon="mail" width="16" height="16" className="mr-2" /><span>mikejhartman@gmail.com</span></a></div>
                        <div className={`mb-2 ${styles.navContainer}`}><a  className={`flex items-center`} href="https://www.linkedin.com/in/mikejhartman-digital/" target="_blank" rel="noreferrer">
                        <SVGIcon icon="linkedin" className="mr-2" width="16" height="16" />
                        <span>LinkedIn</span>
                        </a></div>
                    </div>
                </div>

                <div className="w-full mt-2">
                    <p className=" leading-snug mb-2">Built with <a className={styles.navLink} href="https://www.gatsbyjs.com/">Gatsby</a>. Hosted on <a className={styles.navLink} href="https://www.netlify.com/">Netlify</a>.</p>
                    <p className="text-xs leading-snug">“Be excellent to each other...<a className={styles.navLink} href="https://www.youtube.com/watch?v=rph_1DODXDU" target="_blank" rel="noreferrer">and party on, dudes!</a>”</p>
                    <p className="text-xs leading-snug mt-8">© Mike Hartman {year}</p>
                    
                </div>


            </div>
        </div >
    );

}

export default Footer;