import React, { Component } from "react"
import { CSSTransition } from "react-transition-group"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Squash as Hamburger } from 'hamburger-react';
import SVGIcon from "../SVGIcon/SVGIcon";


import resume from "../../misc/mikejhartman_resume.pdf"
import navStyles from "./NavigationMobile.module.scss"

class NavigationMobile extends Component {
  constructor(props) {
    super(props)
    this.state = { overlay: false }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscape, false)
  }

  handleEscape = e => {
    if (e.keyCode === 27) {
      this.setState({ overlay: false })
    }
  }

  toggleOverlay() {
    const currentState = this.state.overlay
    this.setState({ overlay: !currentState })
  }

  render() {

    const overlay = this.state.overlay;

    return (
      <div className={`ml-auto mr-0 text-right inline-block sm:hidden `}>
        <div className={''}>
          <Hamburger
            toggled={overlay}
            hideOutline={false}
            toggle={() => this.toggleOverlay()}
            label="Show menu"
            
          />
        </div>

        <CSSTransition
          timeout={300}
          in={this.state.overlay}
          className={`${navStyles.navoverlay} `}
          classNames={{ ...navStyles }}
        >
          <div>
            <nav className="mx-auto px-2 w-11/12 md:w-3/4">
              <div className="w-full flex flex-col flex-wrap  text-center">
                <div className="text-center mb-6">
                  <p
                    style={{ color: "#5D5D5D" }}
                    className="mb-1 font-semibold"
                  >
                    Navigation
                  </p>
                  <div className="mb-2" onClick={() => this.toggleOverlay()} role="button" tabIndex="0">
                    <AnchorLink
                      className="font-medium text-xl"
                      style={{ color: "#ffffff", fontWeight: "500" }}
                      to="/"
                    >
                      Home
                    </AnchorLink>
                  </div>
                  <div className="mb-2" onClick={() => this.toggleOverlay()} role="button" tabIndex="0">
                    <AnchorLink
                      className="font-medium text-xl"
                      style={{ color: "#ffffff", fontWeight: "500" }}
                      to="/work"
                    >
                      Work
                    </AnchorLink>
                  </div>
                  <div className="mb-2 hover:text-gray-600">
                    <AnchorLink
                      className="font-medium text-xl"
                      style={{ color: "#ffffff", fontWeight: "500" }}
                      to="/about"
                    >
                      About
                    </AnchorLink>
                  </div>
                

                  <div className="mb-2 hover:text-gray-600">
                    <AnchorLink
                      className="font-medium text-xl"
                      style={{ color: "#ffffff", fontWeight: "500" }}
                      to="/thoughts"
                    >
                      Thoughts
                    </AnchorLink>
                  </div>
                  
                  <div className="mb-2">
                    <a
                      className="font-medium text-xl"
                      
                      href={resume}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Résumé
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <p
                    style={{ color: "#5D5D5D" }}
                    className="mb-1 font-semibold"
                  >
                    Contact
                  </p>
                  <div className="mb-2">
                    <a
                      
                      href="mailto:mikejhartman@gmail.com"
                      className="flex items-center justify-center"
                    >
                    <SVGIcon icon="mail" width="20" height="20" className="mr-2" />
                      <span>mikejhartman@gmail.com</span>
                    </a>
                  </div>
                  <div className="mb-2">
                    <a

                      
                      href="https://www.linkedin.com/in/mikejhartman-digital/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-center mr-2 mt-3"
                    >
                    <SVGIcon icon="linkedin" width="20" height="20" className="mr-2" />
                      <span>LinkedIn</span>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default NavigationMobile
